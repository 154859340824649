/* Change the color and size of the dots */
.slick-dots li button:before {
  color: gray; /* Change this to your desired color */
  font-size: 10px; /* Reduce this value to make the dots smaller */
margin :8px
}

/* Change the color and size of the active dot */
.slick-dots li.slick-active button:before {
  color: rgb(5, 173, 131); /* Change this to your desired active color */
  font-size: 10px; /* Reduce this value to make the active dot smaller */
}

/* Adjust spacing and styling of the dots container */
.slick-dots {
  background-color: white;
  padding: 5px 0; /* Optional: Adjust vertical padding */
  margin-top: 10px; /* Adjust margin as needed */
  display: flex;
  justify-content: center;
}

/* Reduce size and spacing of individual dots */
.slick-dots li {
  margin: 0 1px; /* Adjust horizontal margin between dots */
}

.slick-dots li button:before {
  width: 8px; /* Reduce this value to make the dots smaller */
  height: 8px; /* Reduce this value to make the dots smaller */
}

/* Additional styling for custom dots */
.dot-custom {
  width: 6px; /* Reduce this value to make the dots smaller */
  height: 6px; /* Reduce this value to make the dots smaller */
  border-radius: 50%;
  background-color: white; /* Change this to your desired color */
}
.slider-box {
  height: 193px; /* Set the height here */
  background: rgba(27, 108, 181, 0.37);

 }
 .slider-box-content{
  background: rgba(214, 233, 255, 1);
 }

.slider {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: 3px solid #1b6cb5;
  border-radius: 15px;
}
.slider-dot {
  background:  rgba(27, 108, 181, 1);
}
.slider-button{
  background: rgba(27, 108, 181, 1);

}
.slider-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.45px;
  
}
.slider-description {
  color: var(--Neutral-Black, #000);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
}
.slider-top{
  width: 112px;
  height: 32px;
  top: 0px;
  left: 0px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: rgb(27, 108, 181);
  position: absolute;
  color: rgba(255, 255, 255, 1);
  line-height: 32px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  
  letter-spacing: 2px;
  text-align: center;

}
.slider-icontext{
  color: rgba(27, 108, 181, 1);
  font-family: Poppins;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.45px;
  width: 100px;
  word-wrap: break-word;
  text-align: center;
  display: inline;
  
}