.styled-box-goal {
  background-color: rgba(0, 0, 0, 0.1); /* 10% black */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
 
}
.button-one-goal {
 font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.15em;
  text-align: center;
 
}

.subscription-goal {
     box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25); /* Box shadow */
    }


.text-style-one-goal {
  font-family: 'Poppins', sans-serif; /* Font family */
  font-weight: 600; /* Font weight */
  font-size: 16px; /* Font size */
  line-height: 20px; /* Line height */
  letter-spacing: -0.1px; /* Letter spacing */
}

.small-text-one-goal {
  font-size: 12px; /* Font size */
  line-height: 20px; /* Line height */
  letter-spacing: -0.1px; /* Letter spacing */
}
.paragraph-one-goal{

  letter-spacing: -0.45px; /* Letter spacing */
 
 
}



