
.label-left {
  left: 15px; /* Adjust as needed */
}
.default-bg {
  background-color: #705AE2;
}

.blur-bg {
  background-color: #2e2750;
}


.box-style-add-payments {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); /* Box shadow */
  border-radius: 40.5px; /* Border radius */
}

.button-styles-p-port {

  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 13px;
 
  color: #F1F1F1; /* Text color */
  cursor: pointer;
}




.manual-text-port {

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 13px;
 
  color: #FFFFFF; /* Text color */
  cursor: pointer;
}

.button-styles-p-span-meth {

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 13px;
 
  color: #FFFFFF; /* Text color */
  cursor: pointer;
}




