.header-title {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 20px;
  margin-top: 30px;
  letter-spacing: -0.1px;
  text-align: left;
}


.slider-guac {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.box-guac {
 height: 193px; /* Set the height here */
}

.gradient-bg-guac-gr {
  background: linear-gradient(#3ab100 0%, rgb(113, 198, 1) 7%, rgb(174, 221, 1) 20%, rgb(174, 221, 1) 100%);
  
}
.custom-span {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-align: left;
}


.guac-slider-title {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  letter-spacing: -0.45px; /* Approximate value */
  text-align: left;
}

.custom-title {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  letter-spacing: -0.45px;
  text-align: left;
}


.back-arrow {
  width: 40px;
  height: 40px;
  top: 49px;
  background-color: #52ab01;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-arrow img {
  width: 32px;
  height: 32px;
}

.--side-image {
  width: 160px;
}

.--side-image > img {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center center;
}

.__asa_guac_slider:nth-child(2) > .--side-image {
  width: 300px;
}

.__asa_guac_slider:nth-child(3) > .--side-image {
  width: 200px;
}

.--guac-img-gold {
  top: 18px;
  left: 12px;
}

.--guac-img-gold > img {
  width: 80px;
}

.guac-slider-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: -0.1px;
  text-align: left; /* You can adjust this if needed */
  color: #000000; /* Adjust the color as needed */
}

.custom-box {
  width: 346px;
  height: 131px;
  position: absolute;
  top: 694px;
  left: 20px;
  border-radius: 15px;
  border: 2px solid #E0E0E0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 20px; /* This replaces the p-5 padding */
  margin-top: 24px; /* This replaces the mt-6 margin-top */
}

.custom-box p {
  background-color: white;
  font-size: 12px; /* This replaces text-xs */
  font-weight: 500; /* This replaces font-medium */
  color: #6B7280; /* This replaces text-gray-500 */
}

.custom-paragraph {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: #6B7280; /* Assuming this is the desired color */
}

.custom-container {
  height: 131px;
  left: 20px;
  border-radius: 15px;
  border: 2px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px; /* Adjust padding if needed */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust gap between elements if needed */
}

.subscription-status-cancelled{
  color: #DF4949;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
}
.subscription-guac{
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  letter-spacing: -0.1px;
}
.subscription-text-box{
  width: 345px;
height: 226px;
flex-shrink: 0;
border-radius: 15px;
background: #2C2C2C;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.subscription-text-header{
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.1px;
}
.subscription-text-details{
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 166.667% */
letter-spacing: -0.1px;
opacity: 0.5;
}
.subscription-btn-feedback{
  border-radius: 10px;
  background: #4E4E4E;
  width: 162px;
  height: 42px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
  text-transform: capitalize;
  vertical-align: middle;
  line-height: 42px;
}
.subscription-btn-reactivate{
  width: 345px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #46B601;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 51px;
}
.subscription-feedback-head{
  color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
letter-spacing: -0.096px;
}
.subscription-rate-icon{
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 12px;

}
.subscription-rate-icon-inverted{
  background-color: black;
  filter: invert(100%);
}
.subscription-feedback-text{
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  padding: 4px;
  
  width: 346px;
  height: 280px;
  flex-shrink: 0;
  background: #121212;
}
.subscription-feedback-input{
 

  border: none;
  border-radius: 8px;
 
  position: relative;
  width: 95%;
  height: 95%;
  flex-shrink: 0;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.096px;
  background: #121212;

}
.subscription-feedback-input:focus{
  outline: none;
  border: none;
  border: none;
  box-shadow: none;
}
.subscription-btn-submitfeedback{
  width: 345px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #46B601;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 51px;
}
.subscription-btn-submitfeedback-disabled{
  opacity: 0.5;
}
.subscription-feedback-submitted-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1); /* semi-transparent background */
  z-index: 9999; /* ensure it's on top of other elements */
}
.subscription-feedback-submitted{
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  width: 234px;
}


.subscription-barshome-img{
  width: 134px;
  height: 34px;
  flex-shrink: 0;
  position:absolute;
  bottom: 0;
}